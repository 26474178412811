<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <a>Info</a>
          <label class="text-left required">Name</label>
          <input type="text" v-model="name" />
          <label class="text-left">ICO</label>
          <input type="number" v-model="ico" />
          <label class="text-left">Website</label>
          <input type="text" v-model="website" />
          <label class="text-left">Description</label>
          <textarea v-model="description"></textarea>
          <a>Contact</a>
          <label class="text-left">Name</label>
          <input type="text" v-model="contactName" />
          <label class="text-left">Phone</label>
          <input type="text" v-model="contactPhone" />
          <label class="text-left">Email</label>
          <input type="email" v-model="contactEmail" />
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      name: "",
      description: "",
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      website: "",
      ico: null,
    };
  },
  methods: {
    ...mapActions(["addClient"]),
    submit() {
      this.addClient({
        name: this.name,
        description: this.description,
        contactName: this.contactName,
        contactPhone: this.contactPhone,
        contactEmail: this.contactEmail,
        ico: this.ico,
        website: this.website,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>